.image-text-horizontal {
    margin-bottom: 30px;
    background-color: $primary-color;
    color: $white;

    .btn-link {
        padding: 0;
        border: none;
        color: $white;
        text-transform: none;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        position: relative;
        padding-left: $spacer;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 8px;
            border-color: transparent transparent transparent $white;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    figure {
        margin-bottom: 0;
    }

    .autoblock-header {
        font-size: 1.5rem;
    }
}

.image-text-vertical {
   em.offline {
    color: #36a5ac;
   }
}

.autoblock-header,
.autoblock-footer {
    padding: 20px;
}