/* Custom theme below */

$primary-color:             #36a5ac;
$secondary-color:           #e0e1e6;
$text-color:                #313131;
$background-color:          #ffffff;

$transdev-green:            #a3cab8;
$transdev-red:              #ff0000;
$transdev-orange-alt:       #36a5ac;
$transdev-orange:           #f2b999;
$transdev-blue:             #3391aa;
$transdev-purple:           #3c0c44;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "text": $text-color,
    "blue": $transdev-blue,
    "white": #fff,
    "green": $transdev-green,
    "red": $transdev-red,
    "orange": $transdev-orange
)
